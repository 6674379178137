<!-- 大致粗略的简历信息 -->
<template>
  <div class>
    <div
      class="info_wrap"
      v-for="(item, index) in infoData"
      :key="index"
      @click="goDetails(item)"
    >  
      <img
        src="./../assets/images/test/boy.png"
        alt
        v-if="item.gender && item.gender === '男'"
      />
      <img src="./../assets/images/test/girl.png" alt v-else />

      <div class="headSvg">
        <svg-icon
          iconClass="headMan"
          v-if="item.gender && item.gender === '男'"
        ></svg-icon>
        <svg-icon iconClass="headWoman" v-else></svg-icon>
      </div>

      <div style="position: relative">
        <div class="bsaicInfo">
          <p>
            {{ item.name }}
            <span>
              <svg-icon
                iconClass="isDown"
                v-if="isDown.indexOf(item.resume_id) >= 0"
                class="is-down"
              ></svg-icon>

              <svg-icon
                iconClass="isDown"
                v-if="item.is_download ==1 && item.type==1"
                class="is-down"
              ></svg-icon>
 

            </span>
          </p>
          <p>
            {{ item.age }}岁
            <i></i>
            {{ item.education_background }}
            <i></i>
            <span v-if="item.salary_min || item.salary_min != 0"
              >{{ item.salary_min }}-{{ item.salary_max }}元</span
            >
            <span v-else>保密</span>
          </p>

          <p style="color: #ff001b" v-if="item.state_index > 0">
            求职状态：{{ item.state_str }}
          </p>
        </div>

        <div class="expect">
          <p>
            <svg-icon iconClass="post"></svg-icon>
            <span class="fontColor fontPadding">期望职位：</span>

            <span>{{
              item.position_name == "" ? "暂无" : item.position_name 
            }}</span>
          </p>
          <p>
            <svg-icon iconClass="site"></svg-icon>
            <!-- <el-tooltip class="item" effect="dark" placement="right"> -->
            <!-- :content="item.expect_place_str" -->
            <span class="fontColor fontPadding">期望地点：</span>
            <!-- </el-tooltip> -->
            <span>{{ item.place_name == "" ? "暂无" : item.place_name }}</span>
          </p>
          <p>
            <svg-icon iconClass="time"></svg-icon>
            <span class="fontColor fontPadding">更新时间：</span>
            <span>{{
              item.source_update == "" ? "暂无" : item.source_update
            }}</span>
          </p>
        </div>

        <div class="bsaicInfo">
          <p style="color: #ff001b">
            <img
              class="chat"
              title="获取手机号"
              @click="onFuzhi(item,'手机')"
              src="./../assets/images/icon_img/phone.png"
            />

            <img
              class="chat"
              @click="onFuzhi(item,'微信')"
              title="复制微信"
              src="./../assets/images/icon_img/weixin.png"
            />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getResumeDetails, downResume } from "@/api/resume";
import { getradar, resume_downradar, showCity, get_company_place } from "@/api/resume";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    infoData: {
      type: Array,
    },
    isDown: {
      type: Array,
    },
  },
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 复制微信号手机号到剪切板
    onFuzhi(item,mes) {
      //获取数据跳
      //console.log("sssssssssscccccc");
     // console.log(item);
      if(item.type =='2'){
        //大简历复制手机号
        this.liechang_Fuzhi(item,mes);
      }else
      {
        //小简历复制手机号
        this.radar_Fuzhi(item,mes);
        
      }
      
    },
    //猎场复制微信号手机号
    liechang_Fuzhi(item,mes) {
      let resume_id = item.resume_id;
      let obj = { resume_id: resume_id };
      getResumeDetails(obj).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          if (res.data.is_download === 1) {
            const save = function (e) {
              e.clipboardData.setData("text/plain", res.data.basic_phone);
              e.preventDefault(); // 阻止默认行为
            };
            document.addEventListener("copy", save); // 添加一个copy事件
            document.execCommand("copy"); // 执行copy方法
            this.$message({ message: "复制"+mes+"号成功", type: "success" });

            this.$nextTick(() => {
              this.loading = false;
            });
          } else {
            //获取联系方式

            this.$confirm("确认要获取"+mes+"号吗？", "提示", {
              confirmButtonText: "确认",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                let data2 = {
                  resume_id: resume_id,
                };
                downResume(data2).then((res2) => {

                  // console.log("fffffsssssssssssssss")
                  // console.log(res2)
                 
                  if (res2.code === 0) {
                    getResumeDetails(data2).then((res3) => {
                      // console.log(res3);
                      if (res3.code === 0) {
                        if (res3.data.is_download === 1) {
                          const save = function (e) {
                            e.clipboardData.setData(
                              "text/plain",
                              res3.data.basic_phone
                            );
                            e.preventDefault(); // 阻止默认行为
                          };
                          document.addEventListener("copy", save); // 添加一个copy事件
                          document.execCommand("copy"); // 执行copy方法
                          this.$message({
                            message: "复制"+mes+"号成功",
                            type: "success",
                          });

                          this.$nextTick(() => {
                            this.loading = false;
                          });
                        }else
                        {
                          //没有下载
                        }
                      }
                    });
                  }else if(res2.code === 666888){
                    // 666888  是种子，没有积分了  弹出在线支付对话框
                    this.centerDialogVisible = true
                    this.$emit("callBack",1);
                  }else {
                    
                    this.$message({
                      message: res.msg ,
                      type: "warning",
                    });
                  }
                });
              })
              .catch(() => {});
          }

          event.stopPropagation();
        }
      });

      event.stopPropagation();
    },
    
    //雷达库复制微信号手机号
    radar_Fuzhi(item,mes) {

      if (item.self_is_download === 1) {
        //已经下载 直接复制
        this.myfuzhi(item.phone,mes);
      }else{

        //未下载，提示是否下载，然后在复制

        this.$confirm("确认要获取"+mes+"号吗？", "提示", {
              confirmButtonText: "确认",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                
                //下载小简历

                // console.log(item)
                  resume_downradar({ elite_id: item.elite_id , phone:item. phone}).then((res) => {
                  if (res.code === 0) {
                    this.myfuzhi(item.phone,mes);
                 
                  }else if(res.code === 666888){
                    // 666888  是种子，没有积分了  弹出在线支付对话框
                    // 666888  是种子，没有积分了  弹出在线支付对话框
                    this.centerDialogVisible = true
                    this.$emit("callBack",1);
                  }else if  (res.code === 6888) {
                    
                    this.myfuzhi(item.phone,mes);

                  }
                  else if  (res.code === 203006) {
                    
                    this.$message({
                      message: res.msg,
                      type: "warning",
                    });
                    
                  }



                });



              })
              .catch(() => {});
          }
 


    },

    myfuzhi(val,mes){
      const save = function (e) {
              e.clipboardData.setData("text/plain", val);
              e.preventDefault(); // 阻止默认行为
            };
            document.addEventListener("copy", save); // 添加一个copy事件
            document.execCommand("copy"); // 执行copy方法
            this.$message({ message: "复制"+mes+"号成功", type: "success" });

            this.$nextTick(() => {
              this.loading = false;
            });
    },

 

    // 点击进入详情页，大小简历合并后取消
    goDetails(item) {
       
      

      // if (item.type == '2')
      // {

      //   this.$router.push({
      //     path: "/resumedetails",
      //     query: { id: item.resume_id },
      //   });

      // }else
      // {


      // }



    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // console.log(this.$props.infoData);
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
.info_wrap {
  width: 88%;
  height: 80px;
  margin: 0 auto;
  margin-bottom: 10px;
  background-color: #fff;
  padding: 20px 20px;
  color: $jd_fontColor;
  font-size: $jd_fontSize;
  /* text-align: center; */
  /* line-height: 200px; */
  display: flex;
  position: relative;
  cursor: pointer;
}
.info_wrap:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
// .info_wrap:hover .look {
//   display: block;
// }
// .expect p span:nth-last-child(1) {
//   font-size: $self_fontSize;
//   color: $jd_fontColor;
// }
/* jd和自我评价 */
// .fontColor {
//   color: $selfColor;
// }
/* 自我评价 */
.fontPadding {
  padding-left: 10px;
}
p {
  margin: 0;
  line-height: 27px;
}
i {
  padding-left: 7px;
  margin-left: 7px;

  border-left: 1px solid #e4e7ed;
}
img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.headSvg {
  position: relative;
  left: -10px;
  top: 0;
  z-index: 50;
}
/*基本信息 */
.bsaicInfo {
  margin-left: 10px;
  float: left;
  width: 230px;
  height: 100%;

  /* background-color: red; */
}
// .selfAppraisal {
//   /* 多行超出隐藏 */
//   display: -webkit-box;
//   -webkit-box-orient: vertical;
//   -webkit-line-clamp: 3;
//   overflow: hidden;
//   height: 57px;
//   height: 48px\0;
// }
/* 期望 */
.expect {
  float: left;

  width: 450px;
}
.expect p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* 查看 */
// .look {
//   // float: left;

//   position: absolute;
//   top: -20px;
//   right: 15px;
//   display: none;
//   z-index: 50;
// }
// .look /deep/ .el-button {
//   background-color: #ff8f00;
//   color: #fff;
//   font-size: 16px;
//   border: none;
// }
.head {
  font-size: 50px;
  color: brown;
}
.is-down {
  width: 50px;
  height: 24px;
  vertical-align: middle;
}

.chat {
  width: 30px;
  height: 30px;
  margin-left: 20px;
  float: left;
}
</style>
