let city = [{
    'lng': 116.422401, 'lat': 39.934827, 'label': '北京', 'value': '1',
    'children': [
        { 'lng': 116.404269, 'lat': 39.916706, 'label': '不限', 'value': '1143' },
        { 'lng': 116.292435, 'lat': 39.945972, 'label': '北京周边', 'value': '1146' },
        { 'lng': 116.29845, 'lat': 39.95933, 'label': '海淀区', 'value': '1143' },
        { 'lng': 116.443108, 'lat': 39.921471, 'label': '朝阳区', 'value': '1142' },
        { 'lng': 116.458275, 'lat': 39.557869, 'label': '大兴区', 'value': '6809' },
        { 'lng': 116.65714, 'lat': 39.90998, 'label': '通州区', 'value': '1147' },
        { 'lng': 116.23128, 'lat': 40.22077, 'label': '昌平区', 'value': '1150' },
        { 'lng': 116.22299, 'lat': 39.90569, 'label': '石景山区', 'value': '1145' },
        { 'lng': 116.143265, 'lat': 39.749146, 'label': '房山区', 'value': '6808' },
        { 'lng': 116.10146, 'lat': 39.94048, 'label': '门头沟区', 'value': '6815' },
        { 'lng': 116.41637, 'lat': 39.92855, 'label': '东城区', 'value': '1138' },
        { 'lng': 116.65477, 'lat': 40.13012, 'label': '顺义区', 'value': '6810' },
        { 'lng': 115.97503, 'lat': 40.45678, 'label': '延庆区', 'value': '6813' },
        { 'lng': 116.63177, 'lat': 40.316, 'label': '怀柔区', 'value': '6812' },
        { 'lng': 116.84317, 'lat': 40.37625, 'label': '密云区', 'value': '6811' },
        { 'lng': 116.36611, 'lat': 39.91231, 'label': '西城区', 'value': '1139' },
        { 'lng': 116.28616, 'lat': 39.85856, 'label': '丰台区', 'value': '1144' },
        { 'lng': 117.12141, 'lat': 40.14062, 'label': '平谷区', 'value': '6814' }]
},
{
    'lng': 113.88308, 'lat': 22.55329, 'label': '深圳', 'value': '4',
    'children': [
        { 'lng': 114.05454, 'lat': 22.52291, 'label': '不限', 'value': '1809' },
        { 'lng': 114.1325, 'lat': 22.607901, 'label': '布吉', 'value': '1855' },
        { 'lng': 114.416298, 'lat': 22.639656, 'label': '大鹏新区', 'value': '14726' },
        { 'lng': 114.23679, 'lat': 22.557, 'label': '盐田区', 'value': '1814' },
        { 'lng': 114.37075, 'lat': 22.743505, 'label': '坪山新区', 'value': '11321' },
        { 'lng': 114.13166, 'lat': 22.54836, 'label': '罗湖区', 'value': '1810' },
        { 'lng': 113.88308, 'lat': 22.55329, 'label': '宝安区', 'value': '1812' },
        { 'lng': 113.88308, 'lat': 22.55329, 'label': '深圳周边', 'value': '2665' },
        { 'lng': 114.01339, 'lat': 22.64921, 'label': '龙华新区', 'value': '15975' },
        { 'lng': 114.05454, 'lat': 22.52291, 'label': '福田区', 'value': '1809' },
        { 'lng': 113.93041, 'lat': 22.53332, 'label': '南山区', 'value': '1811' },
        { 'lng': 114.24779, 'lat': 22.71991, 'label': '龙岗区', 'value': '1813' },
        { 'lng': 113.902046, 'lat': 22.763853, 'label': '光明新区', 'value': '11322' }]
},
{
    'lng': 118.8921, 'lat': 31.32751, 'label': '南京', 'value': '172',
    'children': [{ 'lng': 118.77013, 'lat': 32.06639, 'label': '不限', 'value': '174' },
    { 'lng': 118.7787, 'lat': 31.99226, 'label': '雨花台区', 'value': '8774' },
    { 'lng': 118.592461, 'lat': 31.830589, 'label': '滨江开发区', 'value': '116624' },
    { 'lng': 118.90907, 'lat': 32.09636, 'label': '栖霞区', 'value': '181' },
    { 'lng': 118.77013, 'lat': 32.06639, 'label': '鼓楼区', 'value': '174' },
    { 'lng': 118.79809, 'lat': 32.04835, 'label': '玄武区', 'value': '173' },
    { 'lng': 118.8921, 'lat': 31.32751, 'label': '高淳县', 'value': '8810' },
    { 'lng': 118.73145, 'lat': 32.00335, 'label': '建邺区', 'value': '175' },
    { 'lng': 119.0284, 'lat': 31.651, 'label': '溧水县', 'value': '8798' },
    { 'lng': 118.62841, 'lat': 32.059, 'label': '浦口区', 'value': '8783' },
    { 'lng': 118.84002, 'lat': 31.95266, 'label': '江宁区', 'value': '2965' },
    { 'lng': 118.82155, 'lat': 32.32222, 'label': '六合区', 'value': '8789' },
    { 'lng': 118.798286, 'lat': 32.085388, 'label': '南京周边', 'value': '1914' },
    { 'lng': 118.7945, 'lat': 32.03899, 'label': '秦淮区', 'value': '177' }]
},
{
    'lng': 112.98626, 'lat': 28.25591, 'label': '长沙', 'value': '414',
    'children': [{ 'lng': 113.03539, 'lat': 28.20279, 'label': '不限', 'value': '416' },
    { 'lng': 113.08093, 'lat': 28.24595, 'label': '长沙县', 'value': '115185' },
    { 'lng': 112.93134, 'lat': 28.23529, 'label': '岳麓区', 'value': '415' },
    { 'lng': 113.03539, 'lat': 28.20279, 'label': '芙蓉区', 'value': '416' },
    { 'lng': 112.55183, 'lat': 28.27741, 'label': '宁乡', 'value': '115186' },
    { 'lng': 112.8179, 'lat': 28.36121, 'label': '望城', 'value': '21590' },
    { 'lng': 112.98977, 'lat': 28.11415, 'label': '天心区', 'value': '417' },
    { 'lng': 113.096054, 'lat': 28.225647, 'label': '星沙', 'value': '9249' },
    { 'lng': 113.64317, 'lat': 28.16378, 'label': '浏阳', 'value': '115184' },
    { 'lng': 112.98626, 'lat': 28.25591, 'label': '其它', 'value': '1934' },
    { 'lng': 112.98626, 'lat': 28.25591, 'label': '开福区', 'value': '418' },
    { 'lng': 113.03555, 'lat': 28.13551, 'label': '雨花区', 'value': '419' }]
},
{
    'lng': 113.27324, 'lat': 23.15792, 'label': '广州', 'value': '3',
    'children': [{ 'lng': 113.3172, 'lat': 23.08331, 'label': '不限', 'value': '1657' }, { 'lng': 113.288872, 'lat': 23.131935, 'label': '广州周边', 'value': '2689' },
    { 'lng': 113.505951, 'lat': 22.85141, 'label': '南沙区', 'value': '15152' },
    { 'lng': 113.27324, 'lat': 23.15792, 'label': '白云区', 'value': '1659' },
    { 'lng': 113.36199, 'lat': 23.12463, 'label': '天河区', 'value': '1654' },
    { 'lng': 113.8109, 'lat': 23.26093, 'label': '增城区', 'value': '6524' },
    { 'lng': 113.3172, 'lat': 23.08331, 'label': '海珠区', 'value': '1657' },
    { 'lng': 113.469063, 'lat': 22.898252, 'label': '番禺区', 'value': '1662' },
    { 'lng': 113.27324, 'lat': 23.15792, 'label': '萝岗区', 'value': '6547' },
    { 'lng': 113.24428, 'lat': 23.12586, 'label': '荔湾区', 'value': '1656' },
    { 'lng': 113.58646, 'lat': 23.54835, 'label': '从化区', 'value': '6491' },
    { 'lng': 113.2668, 'lat': 23.12901, 'label': '越秀区', 'value': '1658' },
    { 'lng': 113.48067, 'lat': 23.18139, 'label': '黄埔区', 'value': '6501' },
    { 'lng': 113.52497, 'lat': 22.80169, 'label': '南沙区', 'value': '6541' },
    { 'lng': 113.274551, 'lat': 23.493811, 'label': '花都区', 'value': '6512' }]
},
{
    'lng': 113.6401, 'lat': 34.72468, 'label': '郑州', 'value': '342',
    'children': [{ 'lng': 113.61332, 'lat': 34.74821, 'label': '不限', 'value': '343' }, { 'lng': 113.757477, 'lat': 34.72504, 'label': '经开区', 'value': '12089' },
    { 'lng': 113.720161, 'lat': 34.773911, 'label': '航空港', 'value': '117354' },
    { 'lng': 113.740532, 'lat': 34.395557, 'label': '新郑', 'value': '115209' },
    { 'lng': 113.662529, 'lat': 34.754791, 'label': '郑州周边', 'value': '5516' },
    { 'lng': 113.61332, 'lat': 34.74821, 'label': '中原区', 'value': '343' },
    { 'lng': 113.67739, 'lat': 34.75381, 'label': '管城区', 'value': '346' },
    { 'lng': 113.6401, 'lat': 34.72468, 'label': '二七区', 'value': '345' },
    { 'lng': 113.02206, 'lat': 34.74798, 'label': '巩义区', 'value': '115208' },
    { 'lng': 113.66072, 'lat': 34.79977, 'label': '金水区', 'value': '344' },
    { 'lng': 113.050491, 'lat': 34.453667, 'label': '登封区', 'value': '115211' },
    { 'lng': 113.385368, 'lat': 34.799129, 'label': '荥阳区', 'value': '115213' },
    { 'lng': 113.30892, 'lat': 34.8028, 'label': '上街区', 'value': '10730' },
    { 'lng': 113.687042, 'lat': 34.698547, 'label': '郑东新区', 'value': '11336' },
    { 'lng': 113.61694, 'lat': 34.86739, 'label': '惠济区', 'value': '5518' },
    { 'lng': 113.390892, 'lat': 34.539444, 'label': '新密区', 'value': '115210' },
    { 'lng': 113.97624, 'lat': 34.71903, 'label': '中牟区', 'value': '115207' },
    { 'lng': 113.567299, 'lat': 34.817596, 'label': '高新区', 'value': '12088' }]
},
{
    'lng': 104.10194, 'lat': 30.65984, 'label': '成都', 'value': '102',
    'children': [{ 'lng': 104.06291, 'lat': 30.67485, 'label': '不限', 'value': '103' }, { 'lng': 103.64662, 'lat': 30.98837, 'label': '都江堰', 'value': '111' },
    { 'lng': 104.15801, 'lat': 30.82404, 'label': '新都区', 'value': '110' },
    { 'lng': 104.06291, 'lat': 30.67485, 'label': '青羊区', 'value': '103' },
    { 'lng': 104.04311, 'lat': 30.64242, 'label': '武侯区', 'value': '106' },
    { 'lng': 103.92377, 'lat': 30.57447, 'label': '双流区', 'value': '119' },
    { 'lng': 104.057709, 'lat': 30.574909, 'label': '天府新区', 'value': '21604' },
    { 'lng': 103.52071, 'lat': 30.58759, 'label': '大邑区', 'value': '15288' },
    { 'lng': 103.50628, 'lat': 30.19676, 'label': '蒲江区', 'value': '15289' },
    { 'lng': 103.95811, 'lat': 30.99018, 'label': '彭州区', 'value': '115222' },
    { 'lng': 104.10194, 'lat': 30.65984, 'label': '高新西区', 'value': '11461' },
    { 'lng': 104.25122, 'lat': 30.87849, 'label': '青白江区', 'value': '109' },
    { 'lng': 104.08329, 'lat': 30.65618, 'label': '锦江区', 'value': '104' },
    { 'lng': 104.27471, 'lat': 30.55663, 'label': '龙泉驿区', 'value': '108' },
    { 'lng': 104.41205, 'lat': 30.86203, 'label': '金堂县', 'value': '115' },
    { 'lng': 104.10194, 'lat': 30.65984, 'label': '成华区', 'value': '107' },
    { 'lng': 103.90256, 'lat': 30.79589, 'label': '郫都区', 'value': '117' },
    { 'lng': 103.46436, 'lat': 30.41032, 'label': '邛崃市', 'value': '15938' },
    { 'lng': 104.05293, 'lat': 30.69015, 'label': '金牛区', 'value': '105' },
    { 'lng': 104.075623, 'lat': 30.55303, 'label': '高新区', 'value': '11457' },
    { 'lng': 103.900604, 'lat': 30.721479, 'label': '温江区', 'value': '116' },
    { 'lng': 104.54733, 'lat': 30.41133, 'label': '简阳区', 'value': '117990' },
    { 'lng': 104.10194, 'lat': 30.65984, 'label': '成都周边', 'value': '1909' },
    { 'lng': 103.67289, 'lat': 30.63021, 'label': '崇州区', 'value': '15287' },
    { 'lng': 103.81165, 'lat': 30.41026, 'label': '新津区', 'value': '13387' }]
},
{
    'lng': 108.27331, 'lat': 22.78121, 'label': '南宁', 'value': '845',
    'children': [{ 'lng': 108.49693, 'lat': 22.78658, 'label': '不限', 'value': '846' }, { 'lng': 108.32082, 'lat': 22.75619, 'label': '良庆区', 'value': '5082' },
    { 'lng': 108.27331, 'lat': 22.78121, 'label': '其他', 'value': '1975' },
    { 'lng': 108.48737, 'lat': 22.75837, 'label': '邕宁区', 'value': '848' },
    { 'lng': 108.27461, 'lat': 23.15866, 'label': '武鸣区', 'value': '115212' },
    { 'lng': 108.31343, 'lat': 22.83393, 'label': '西乡塘区', 'value': '849' },
    { 'lng': 108.49693, 'lat': 22.78658, 'label': '青秀区', 'value': '846' },
    { 'lng': 108.60506, 'lat': 23.43205, 'label': '上林区', 'value': '115216' },
    { 'lng': 108.17702, 'lat': 23.70817, 'label': '马山区', 'value': '115215' },
    { 'lng': 109.26147, 'lat': 22.67999, 'label': '横县区', 'value': '115218' },
    { 'lng': 108.27331, 'lat': 22.78121, 'label': '江南区', 'value': '850' },
    { 'lng': 108.36836, 'lat': 22.85429, 'label': '兴宁区', 'value': '847' },
    { 'lng': 108.8103, 'lat': 23.21768, 'label': '宾阳区', 'value': '115217' },
    { 'lng': 107.6961, 'lat': 23.16589, 'label': '隆安区', 'value': '115214' }]
},
{
    'lng': 108.93425, 'lat': 34.23053, 'label': '西安', 'value': '483',
    'children': [{ 'lng': 108.90688, 'lat': 34.15775, 'label': '不限', 'value': '3194' }, { 'lng': 108.824722, 'lat': 34.267132, 'label': '西咸新区', 'value': '116630' },
    { 'lng': 109.08822, 'lat': 34.53487, 'label': '高陵区', 'value': '115199' },
    { 'lng': 108.22213, 'lat': 34.1634, 'label': '周至区', 'value': '115204' },
    { 'lng': 108.90688, 'lat': 34.15775, 'label': '长安区', 'value': '3194' },
    { 'lng': 108.9402, 'lat': 34.26703, 'label': '莲湖区', 'value': '484' },
    { 'lng': 109.06458, 'lat': 34.2727, 'label': '灞桥区', 'value': '488' },
    { 'lng': 108.93425, 'lat': 34.23053, 'label': '西安周边', 'value': '1939' },
    { 'lng': 108.96074, 'lat': 34.26665, 'label': '新城区', 'value': '485' },
    { 'lng': 109.033287, 'lat': 34.491638, 'label': '经开区', 'value': '116629' },
    { 'lng': 108.94878, 'lat': 34.22259, 'label': '雁塔区', 'value': '487' },
    { 'lng': 108.93425, 'lat': 34.23053, 'label': '碑林区', 'value': '486' },
    { 'lng': 108.94712, 'lat': 34.29318, 'label': '未央区', 'value': '489' },
    { 'lng': 109.32345, 'lat': 34.15132, 'label': '蓝田区', 'value': '115205' },
    { 'lng': 108.88913, 'lat': 34.195202, 'label': '高新区', 'value': '5926' },
    { 'lng': 108.60494, 'lat': 34.10847, 'label': '鄠邑区', 'value': '115267' },
    { 'lng': 109.21421, 'lat': 34.3667, 'label': '临潼区', 'value': '3116' },
    { 'lng': 109.036041, 'lat': 34.313721, 'label': '浐灞区', 'value': '116627' },
    { 'lng': 108.956131, 'lat': 34.193699, 'label': '曲江新区', 'value': '12255' },
    { 'lng': 109.2262, 'lat': 34.66225, 'label': '阎良区', 'value': '490' },
    { 'lng': 108.896202, 'lat': 34.292992, 'label': '大兴新区', 'value': '116628' }]
},
{
    'lng': 114.02919, 'lat': 30.58203, 'label': '武汉', 'value': '158',
    'children': [{ 'lng': 114.08469, 'lat': 30.30895, 'label': '不限', 'value': '167' }, { 'lng': 114.38415, 'lat': 30.64039, 'label': '青山区', 'value': '8000' },
    { 'lng': 114.21772, 'lat': 30.55473, 'label': '汉阳区', 'value': '163' },
    { 'lng': 114.08469, 'lat': 30.30895, 'label': '汉南区', 'value': '167' },
    { 'lng': 114.80114, 'lat': 30.84154, 'label': '新洲区', 'value': '171' },
    { 'lng': 114.02919, 'lat': 30.58203, 'label': '蔡甸区', 'value': '168' },
    { 'lng': 114.159637, 'lat': 30.48984, 'label': '沌口开发区', 'value': '15298' },
    { 'lng': 114.13702, 'lat': 30.61996, 'label': '东西湖区', 'value': '166' },
    { 'lng': 114.34253, 'lat': 30.49984, 'label': '洪山区', 'value': '165' },
    { 'lng': 114.02919, 'lat': 30.58203, 'label': '武汉周边', 'value': '1913' },
    { 'lng': 114.3096, 'lat': 30.59982, 'label': '江岸区', 'value': '159' },
    { 'lng': 114.27096, 'lat': 30.60147, 'label': '江汉区', 'value': '161' },
    { 'lng': 114.37509, 'lat': 30.88131, 'label': '黄陂区', 'value': '170' },
    { 'lng': 114.21498, 'lat': 30.58145, 'label': '硚口区', 'value': '162' },
    { 'lng': 114.34253, 'lat': 30.49984, 'label': '武昌区', 'value': '160' },
    { 'lng': 114.32168, 'lat': 30.37559, 'label': '江夏区', 'value': '169' }]
},
{
    'lng': 120.28429, 'lat': 31.52853, 'label': '无锡', 'value': '93',
    'children': [{ 'lng': 120.29834, 'lat': 31.68096, 'label': '不限', 'value': '7204' }, { 'lng': 120.29834, 'lat': 31.68096, 'label': '惠山区', 'value': '7204' },
    { 'lng': 120.28429, 'lat': 31.52853, 'label': '滨湖区', 'value': '99' },
    { 'lng': 120.36434, 'lat': 31.49055, 'label': '新吴区', 'value': '11299' },
    { 'lng': 120.289391, 'lat': 31.571833, 'label': '梁溪区', 'value': '116626' },
    { 'lng': 120.28429, 'lat': 31.52853, 'label': '无锡周边', 'value': '1908' },
    { 'lng': 120.35761, 'lat': 31.58849, 'label': '锡山区', 'value': '97' }]
},
{
    'lng': 115.94422, 'lat': 28.54538, 'label': '南昌', 'value': '669',
    'children': [{ 'lng': 115.891388, 'lat': 28.583433, 'label': '不限', 'value': '12688' }, { 'lng': 115.94422, 'lat': 28.54538, 'label': '新建区', 'value': '675' },
    { 'lng': 115.891388, 'lat': 28.583433, 'label': '象湖区', 'value': '12688' },
    { 'lng': 115.962051, 'lat': 28.715002, 'label': '高新开发区', 'value': '12656' },
    { 'lng': 115.89925, 'lat': 28.68503, 'label': '其它', 'value': '1956' },
    { 'lng': 115.89925, 'lat': 28.68503, 'label': '东湖区', 'value': '670' },
    { 'lng': 115.89925, 'lat': 28.68503, 'label': '红谷滩新', 'value': '12024' },
    { 'lng': 115.94422, 'lat': 28.54538, 'label': '湾里区', 'value': '10620' },
    { 'lng': 115.891876, 'lat': 28.540865, 'label': '小蓝经济开发区', 'value': '16048' },
    { 'lng': 115.94422, 'lat': 28.54538, 'label': '青山湖区', 'value': '674' },
    { 'lng': 115.94422, 'lat': 28.54538, 'label': '青云谱区', 'value': '672' },
    { 'lng': 115.94422, 'lat': 28.54538, 'label': '南昌县', 'value': '676' },
    { 'lng': 115.840889, 'lat': 28.734579, 'label': '昌北经济开发区', 'value': '16049' },
    { 'lng': 115.94422, 'lat': 28.54538, 'label': '安义', 'value': '115187' },
    { 'lng': 115.94422, 'lat': 28.54538, 'label': '西湖区', 'value': '671' }]
},
{
    'lng': 117.30794, 'lat': 31.79322, 'label': '合肥', 'value': '837',
    'children': [{ 'lng': 117.16766, 'lat': 32.47796, 'label': '不限', 'value': '115200' }, { 'lng': 117.26497, 'lat': 31.87872, 'label': '庐阳区', 'value': '838' },
    { 'lng': 117.308975, 'lat': 31.883444, 'label': '新站区', 'value': '11364' },
    { 'lng': 117.18283, 'lat': 31.70689, 'label': '肥西区', 'value': '115202' },
    { 'lng': 117.16766, 'lat': 32.47796, 'label': '长丰区', 'value': '115200' },
    { 'lng': 117.30949, 'lat': 31.85797, 'label': '瑶海区', 'value': '839' },
    { 'lng': 117.247108, 'lat': 32.03022, 'label': '北城新区', 'value': '22168' },
    { 'lng': 117.209579, 'lat': 31.778419, 'label': '经开区', 'value': '11362' },
    { 'lng': 117.30794, 'lat': 31.79322, 'label': '包河区', 'value': '841' },
    { 'lng': 117.227242, 'lat': 31.821398, 'label': '政务区', 'value': '11366' },
    { 'lng': 117.46928, 'lat': 31.8877, 'label': '肥东区', 'value': '115201' },
    { 'lng': 117.28736, 'lat': 31.25567, 'label': '庐江区', 'value': '117026' },
    { 'lng': 117.288391, 'lat': 31.732189, 'label': '滨湖新区', 'value': '11365' },
    { 'lng': 117.26061, 'lat': 31.8512, 'label': '蜀山区', 'value': '840' },
    { 'lng': 117.30794, 'lat': 31.79322, 'label': '其他', 'value': '1974' },
    { 'lng': 117.88937, 'lat': 31.62329, 'label': '巢湖区', 'value': '116622' },
    { 'lng': 117.135788, 'lat': 31.83128, 'label': '高新区', 'value': '11363' }]
}]

module.exports = city;