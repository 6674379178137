<!-- 猎场搜索页面 -->
<template>
  <div class="wrap">
    <div class="search_wrap">
      <div class="search-input">
        <div class="search">
          <!-- <p>城市选择</p> -->
          <el-cascader
            v-model="valueCity"
            placeholder="全国"
            @change="handleChange"
            :options="optionsCity"
            separator="-"
          ></el-cascader>
          <!-- :show-all-levels="false" -->
          <em></em>
          <el-input
            v-model="input"
            placeholder="职位，学历"
            class="inline_input"
          >
            <span slot="suffix" @click="search">
              <i class="el-icon-search search-icon"></i>
            </span>
          </el-input>
          <!-- <el-button>搜索</el-button> -->
        </div>
        <!-- <span>
          <el-tag
            closable
            @close="handleClose(index)"
            class="history-search"
            v-for="(item, index) in historySearch"
            :key="index"
            @click="tagSearch(item)"
          >{{item}}</el-tag>
        </span>-->
      </div>
      <div class="filtrate">
        <div>
          <p>行业选择</p>
          <el-select
            v-model="valueProfession"
            placeholder="不限"
            @change="professionHandle"
          >
            <el-option
              v-for="item in optionsProfession"
              :key="item.value"
              :label="item.title"
              :value="item.position_id"
            ></el-option>
          </el-select>
          <!-- <el-cascader
            :options="optionsProfession"
            :props="{ value: 'position_id', label: 'title'}"
            @change="handleChange"
          ></el-cascader>-->
        </div>
        <div>
          <p>学历要求</p>
          <el-select v-model="valueEdu" placeholder="不限" @change="eduHandle">
            <el-option
              v-for="item in optionsEdu"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div>
          <p>年龄</p>
          <el-select v-model="valueAge" placeholder="不限" @change="ageHandle">
            <el-option
              v-for="item in optionsAge"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </div>
        <div>
          <p>性别</p>
          <el-select v-model="valueSex" placeholder="不限" @change="sexHandle">
            <el-option
              v-for="item in optionsSex"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>

      <!--  右侧浮动框
      <div class="activity"></div>
      -->

      <div class="chongZhi">
        <span class="iconfont iconVIP tubiao"></span> 您目前剩
        <span class="shuzi"> {{ integral }} </span> 积分，是否
        <button
          type="button"
          class="el-button chongz el-button--warning el-button--mini"
          @click="centerDialogVisible = true"
        >
          <span> 充值 </span>
        </button>
      </div>
    </div>

    <div class="hang"></div>

    <div class="main_content">
      <RoughlyInfoComponent
        :infoData="list"
        :isDown="is_download"
        @callBack="callBack"
      ></RoughlyInfoComponent>
    </div>
    <!-- <div class="bottom">
      <p v-if="currentpage < 20">没有更多了</p>
    </div> -->
    <div class="pagination">
      <!--ggg修改的 开始 -->
      <!--原来的 开始  
      <el-pagination
        :hide-on-single-page="true"
        background
        :page-size="20"
        :current-page="currentpage"
        layout="prev, pager, next"
        :total="pageTotal"
        @current-change="pageNowHandler"
      ></el-pagination>
      原来的 结束 -->
      <el-pagination
        :hide-on-single-page="true"
        background
        :page-size="20"
        :current-page="currentpage"
        layout="prev, next"
        :total="pageTotal"
        @current-change="pageNowHandler"
      ></el-pagination>
      <!--ggg修改的 结束 -->
    </div>

    <!--弹出支付 开始 -->
    <el-dialog
      title="在线充值"
      :visible.sync="centerDialogVisible"
      width="500px"
      center
    >
      <div class="chongzhiC">
        <div class="payConent">
          <div>
            <div class="payHeader">
              <div>充值惊喜价 <span>￥ 300</span></div>
              <div>
                <ul>
                  <li>1.可下载100份简历</li>
                  <li>2.不限量简历线索获取</li>
                  <li>3.免费使用社交裂变及短视频招聘广告发布</li>
                  <li>4.免费使用招聘流程管理与数据统计功能</li>
                </ul>
              </div>
            </div>

            <div class="fangshi">
              <div>支付方式：</div>
              <div>
                <span class="iconfont iconweixinzhifu"></span>
                微信支付
              </div>
            </div>
            <div>
              &nbsp;&nbsp; 实付：<span class="netPay">300</span>
              元
            </div>
          </div>
        </div>

        <div>
          <div>
            <div style="float: left">支付二维码：</div>
            <div class="aa" v-if="code_url != ''">
              <vue-qr :text="code_url" :size="250" :logoScale="0.3"> </vue-qr>

              <div v-if="is_overdue == true" class="is_payImgBg">
                二维码失效
              </div>
            </div>
          </div>

          <div class="nowPay" @click="qrcode_pay">生成支付二维码</div>
        </div>
      </div>
    </el-dialog>
    <!--弹出支付 结束 -->
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
// import { data } from '@/mock/index'
import { mapState, mapActions } from "vuex";
import VueQr from "vue-qr";
import {
  getResumeData,
  getsearchResumeData,
  getIsDown,
  showCity,
  get_company_place,
  qrcode_pay,
  get_integral,
  get_zhifu_state,
} from "@/api/resume";
import axios from "axios";
import cityList from "@/utils/city";
import { profession, educationBackground, age, sex } from "@/utils/searchtype";
import RoughlyInfoComponent from "@/components/roughlyInfo";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { RoughlyInfoComponent, VueQr },
  data() {
    //这里存放数据
    return {
      // 搜索
      input: "",
      //搜索历史纪录
      historySearch: [],
      /**
       * 城市
       * 行业
       * 学历
       * 年纪
       * 性别
       */
      valueCity: [],
      valueProfession: [],
      valueEdu: "",
      valueAge: "",
      valueSex: "",
      /**
       * 城市
       * 行业
       * 学历
       * 年纪
       * 性别
       */
      optionsCity: [],
      optionsProfession: [],
      optionsEdu: [],
      optionsAge: [],
      optionsSex: [],
      //简历数据
      list: [],
      //是否还有数据
      isInfoData: true,
      //dev
      devCount: 0,
      isScroll: true,
      searchType: 0,
      //api需要

      page: 0,
      gender: 0,
      min_age: 0,
      max_age: 99,
      edu_id_min: "",
      edu_id_max: 8,
      place_id: "",
      // is_download: []
      pageTotal: 0,
      currentpage: 1,

      uid: "", //订单编号
      centerDialogVisible: false, //是否弹出支付页面
      integral: 0, //当前用户实时积分

      code_url: "", //支付二维码路径或者

      is_overdue: false, //二维码是否过期 true 是过期，false 未过期
      timer: null, //定时器
      timer2: null,
      num: 0, //检测120秒
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      is_download: (state) => state.tool.isDown,
    }),
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    ...mapActions({
      getResumeIsDown: "tool/getResumeIsDown",
    }),

    //子组件控制弹出支付对话框
    callBack(flag) {
      if (flag == 1) {
        this.centerDialogVisible = true;
      }
    },
    // 获取该用户积分
    get_integral() {
      get_integral({}).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          this.integral = res.data.integral;
        }
      });
    },

    //弹出二维码支付
    qrcode_pay() {
      qrcode_pay({}).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          this.code_url = res.data.code_url;
          this.uid = res.data.uid;
          this.get_is_overdue();
          this.get_is_zhifu() ;
        }
      });
    },

    //判断是否支付成功,定时监测
    get_is_zhifu() {
      // 实现轮询
      this.timer2 = window.setInterval(() => {
        setTimeout(this.get_zhifu_state(), 0);
      }, 1000);
    },

    //判断是否支付成功
    get_zhifu_state() {
      console.log("dddd");
      let that = this;
      get_zhifu_state({ uid: this.uid }).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          that.$message({ message: "支付成功", type: "success" });
          clearInterval(this.timer2);
          this.timer2 = null;
          //刷新当前页面
          window.setInterval(() => {
            setTimeout(function () {
              that.$router.go(0);
            }, 0);
          }, 2000);
        }
      });
    },

    //判断二维码是否过期
    get_is_overdue() {
      this.num = 0;
      this.is_overdue = false;

      // 实现轮询
      this.timer = window.setInterval(() => {
        setTimeout(this.getNum(), 0);
      }, 1000);
    },

    //定制二维码过期检测
    stop() {
      clearInterval(this.timer);
      this.timer = null;
    },
    // 请求是否有新消息
    getNum() {
      this.num++;
      console.log("请求" + this.num + "次");
      if (this.num == 120) {
        this.is_overdue = true;
        this.stop();
      }
    },

    //城市选择
    handleChange(value) {
      // console.log(value);
      this.place_id = Number(value[value.length - 1]);
      this.currentpage = 1;
      this.searchType = 0;
      this.page = 0;
      this.list = [];
      this.getallData();
    },
    //行业
    professionHandle(value) {
      // console.log(value);
      this.valueProfession = value;
      // console.log(value);
      this.currentpage = 1;
      this.list = [];
      this.searchType = 0;
      this.page = 0;
      this.getallData();
    },
    //学历
    eduHandle(value) {
      // console.log(value);
      this.edu_id_min = value;
      // console.log(value);
      this.currentpage = 1;
      this.list = [];
      this.searchType = 0;
      this.page = 0;
      this.getallData();
    },
    //年龄
    ageHandle(value) {
      // console.log(value);
      if (value === "年龄不限") {
        this.min_age = 0;
        this.max_age = 99;
      } else if (value === "45岁以上") {
        this.min_age = 45;
        this.max_age = 99;
      } else {
        let age = value.split("-");
        // console.log(age);
        this.min_age = age[0];
        this.max_age = age[1].split("岁")[0];
        // console.log(value);
      }

      this.list = [];
      this.searchType = 0;
      this.page = 0;
      this.currentpage = 1;
      this.getallData();
    },
    //性别
    sexHandle(value) {
      // console.log(value);
      this.gender = value;
      // console.log(value);
      this.currentpage = 1;
      this.list = [];
      this.searchType = 0;
      this.page = 0;
      this.getallData();
    },
    //搜索
    search() {
      this.list = [];
      this.page = 0;
      this.currentpage = 1;
      // console.log(this.currentpage);
      this.searchType = 1;
      let data = {
        search_data: {
          place_id: this.place_id,
          position_id: this.valueProfession[0],
          min_age: this.min_age,
          max_age: this.max_age,
          gender: Number(this.gender),
          education_background: this.edu_id_min,
        },
        page: 0,
        search_str: this.input,
      };

      this.historySearch.push(this.input);
      getsearchResumeData(data).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          this.isScroll = true;
          this.isInfoData = true;
          this.list = res.data.resume_list;
          if (this.list.length == 0) {
            this.isInfoData = false;
          }
        }
      });
    },
    handleClose(tag) {
      this.historySearch.splice(tag, 1);
    },
    //点击历史记录之后进行搜索
    tagSearch(item) {
      this.input = item;
    },
    //猎场简历
    getallData() {
      let data = {
        search_data: {
          place_id: this.place_id,
          position_id: this.valueProfession[0],
          min_age: this.min_age,
          max_age: this.max_age,
          gender: Number(this.gender),
          education_background: this.edu_id_min,
        },
        page: this.page,
        search_str: this.input,
      };

      getResumeData(data).then((res) => {
        console.log("猎场数据：");
        if (res.code === 0) {
          this.pageTotal = res.data.all_resume_count;
          if (res.data.resume_list.length > 0) {
            if (res.data.resume_list.length < 5) {
              this.isScroll = true;
              this.isInfoData = false;
              this.list = res.data.resume_list;
            } else {
              this.isScroll = true;
              this.isInfoData = true;
              this.list = res.data.resume_list;
            }
          } else {
            this.isInfoData = false;
          }
        }
      });
    },
    //页面跳转
    pageNowHandler(val) {
      this.page = val - 1;
      this.currentpage = val;
      if (this.searchType === 0) {
        console.log("第二页1");
        this.getallData();
      } else if (this.searchType === 1) {
        console.log("第二页");
        let data = {
          search_data: {
            place_id: this.place_id,
            position_id: this.valueProfession[0],
            min_age: this.min_age,
            max_age: this.max_age,
            gender: this.gender,
            education_background: this.edu_id_min,
          },
          page: this.page,
          search_str: this.input,
        };
        getsearchResumeData(data).then((res) => {
          if (res.code === 0) {
            this.pageTotal = res.data.all_resume_count;

            if (res.data.resume_list.length > 0) {
              this.isInfoData = true;
              this.isScroll = true;
              this.list.push(...res.data.resume_list);
            } else if (res.data.resume_list.length === 0) {
              console.log(222);
              this.isInfoData = false;
            }
          }
        });
      }
    },
    // getPosition() {
    //   if (navigator.geolocation) {
    //     //navigator.geolocation.getCurrentPosition这个方法里面有三个参数
    //     //这个会在界面拉出一个消息框，让用户确认是否允许获取位置,不过pc端我试了都是err，
    //     //参1，成功后执行的函数
    //     //参2，失败时执行的函数
    //     //参3，选项配置，下面是在6000毫秒内结束请求
    //     navigator.geolocation.getCurrentPosition(
    //       function(position) {
    //         var latitude = position.coords.latitude
    //         var longitude = position.coords.longitude
    //         console.log('Latitude : ' + latitude + ' Longitude: ' + longitude)
    //         console.log(position)
    //       },
    //       function(err) {
    //         console.log('您的浏览器不支持此项技术')
    //       },
    //       { timeout: 6000 }
    //     )
    //   }
    // }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    //获取当前积分
    this.get_integral();

    this.getResumeIsDown();
    // getIsDown().then(res => {
    //   console.log(res)
    //   this.is_download = res.data.is_download
    // })
    // this.getPosition()
    // this.optionsCity = cityList
    this.optionsProfession = profession;
    this.optionsEdu = educationBackground;
    this.optionsAge = age;
    this.optionsSex = sex;
    // axios.get("/static/area.json").then((res) => {
    //   console.log(res);
    //   this.optionsCity = res;
    // });
    // axios.get('/static/position.json').then(res => {
    //   console.log(res)
    //   this.optionsProfession = res
    // })
    // console.log(
    //   this.valueProfession,
    //   this.valueEdu,
    //   this.valueAge,
    //   this.valueSex
    // );
    let data = {
      search_data: {
        place_id: this.place_id,
        position_id: this.valueProfession[0],
        min_age: this.min_age,
        max_age: this.max_age,
        gender: Number(this.gender),
        education_background: this.edu_id_min,
      },
      page: 0,
      search_str: this.input,
    };
    // return
    getResumeData(data).then((res) => {
      if (res.code === 0) {
        this.pageTotal = res.data.all_resume_count;
        this.valueCity = [
          res.data.place_data.city_code,
          res.data.place_data.district_code,
        ];
        // console.log("猎场数据create：", res.data.resume_list);
        this.list = res.data.resume_list;
        // console.log(this.list);
        if (this.list.length == 0) {
          this.isInfoData = false;
        }
      }
    });

    showCity({}).then((res) => {
      // console.log(res);
      this.optionsCity = res.data;

      // 默认公司地区
      get_company_place({}).then((res) => {
        console.log("sssssssss");
        // console.log(res);

        this.valueCity = [
          res.data.place_data.province_code,
          res.data.place_data.city_code,
          res.data.place_data.district_code,
        ];
      });
    });
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  }, //生命周期 - 销毁完成
  activated() {
    // var scrollTop =
    //   window.pageYOffset ||
    //   document.documentElement.scrollTop ||
    //   document.body.scrollTop
    // console.log(scrollTop)
  }, //如果页面有keep-alive缓存功能，这个函数会触发
  deactivated() {},
};
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
/* 搜索 */
.wrap {
  @include padding;
  // padding: 0;
  // padding-top: 0;
  position: relative;
  // box-sizing: border-box;
}
.search_wrap {
  position: sticky;
  // width: 100%;
  padding-top: $padding_top;
  min-width: 1100px;
  top: 61px;
  z-index: 200;
  position: fixed\0;
  zoom: 1;
  background-color: $main_bc;
  border-bottom: 1px solid #f17a0a;
}
/**地点 搜索 */
// div /deep/ .el-cascader .el-input__inner {
//   border: none;
// }
// .search /deep/ .el-input {
//   width: 300px;
// }
.search /deep/ .el-cascader {
  width: 250px;
}

.search /deep/ .inline_input {
  width: 200px;
}
.search /deep/ .el-input .el-input__inner {
  border: none;
}
.search-input {
  display: flex;
  align-items: center;
  margin-bottom: $padding_top;
}
.search {
  // min-width: 900px;
  border: 1px solid #e3e7ed;
  width: 452px;
  height: 40px;
}
.search-icon {
  width: 40px;
  height: 100%;
  // background: red;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  cursor: pointer;
}
em {
  background-color: #fff;
  height: 40px;
  width: 100%;
  border-left: 1px solid #999999;
}
.inline_input {
  display: inline-block;
  width: 300px;
}
.search /deep/ .el-button {
  background-color: #ff8f00;
  color: #fff;
}
// 历史查询记录
.history-search {
  display: inline-block;
  margin-left: 3px;
  cursor: pointer;
}
/* 筛选 */
.filtrate {
  max-width: 1200px;
  min-width: 1100px;
  height: 120px;
  display: flex;
  background-color: #fff;
  justify-content: space-around;
  align-items: center;
  // border-bottom: 20px solid $main_bc;
}
.filtrate p {
  color: $jd_fontColor;
  font-size: $jd_fontSize;
}
//右侧活动
.activity {
  width: 250px;
  height: 250px;
  background-color: #fff;
  position: absolute;
  // top: 222px;
  top: auto;
  z-index: 30;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("~@/assets/images/test/tool.png");
  background-position: center;
}
// .main_content {
//   // margin-top: -250px;
// }
.bottom > p {
  width: 100px;
  height: 20px;
  margin: 0 auto;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  color: #999999;
}
// 分页
.pagination {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;

  text-align: center;
}

.chongZhi {
  line-height: 40px;
  background: #fff;
  padding-left: 20px;
}
.chongZhi .tubiao {
  font-size: 25px;
  color: #ff8f00;
  font-weight: 700;
}
.chongZhi .shuzi {
  color: #ff8f00;
  font-weight: 700;
}
.chongZhi .chongz {
  font-size: 18px;
  padding: 4px 6px;
}

.hang {
  border-bottom: 20px solid $main_bc;
}

.chongzhiC .payConent {
  overflow: hidden;
}
.chongzhiC div {
  color: #999;
  overflow: hidden;
}
.chongzhiC .payConent > div:first-child {
  width: calc(80% - 26px);
  border: 1px solid #f17a0a;
  padding: 0 12px 12px 12px;
  width: 424px;
}
.chongzhiC .payConent > div {
  float: left;
  margin-bottom: 12px;
}
.chongzhiC .payConent .payHeader > div:first-child {
  font-size: 18px;
  color: #2b2a2a;
  font-weight: 700;
  margin-bottom: 8px;
  padding: 6px 0;
  border-bottom: 1px solid #f17a0a;
}
.chongzhiC .payConent .payHeader > div:first-child span {
  font-family: Times New Roman, Times, serif;
  font-size: 30px;
  color: #000;
}
.chongzhiC div span {
  color: #1b1b1b;
}
.chongzhiC .payConent .payHeader > div:last-child {
  overflow: hidden;
  font-size: 12px;
}
.chongzhiC .payConent .payHeader > div:nth-child(2) {
  font-size: 14px !important;
  color: #f1600c;
  margin-bottom: 8px;
  border-bottom: 1px solid #f17a0a;
}
.chongzhiC .payConent .payHeader > div:nth-child(2) li {
  list-style: inside;
  margin: 4px 0;
}
::marker {
  unicode-bidi: isolate;
  font-variant-numeric: tabular-nums;
  text-transform: none;
  text-indent: 0px !important;
  text-align: start !important;
  text-align-last: start !important;
}
.chongzhiC div .jfen {
  font-size: 20px;
  color: #f7410a;
}
.chongzhiC .fangshi > div {
  float: left;
}
.chongzhiC .fangshi > div:last-child {
  color: #111;
  border: 1px solid #86cc19;
  padding: 0 6px;
  border-radius: 4px;
  line-height: 32px;
  background: #e9f0df;
}
.chongzhiC .fangshi > div:last-child span {
  color: #86cc19;
  font-size: 20px;
  margin-right: 2px;
}
.chongzhiC div .netPay {
  font-size: 20px;
  color: #d30c0c;
}
.chongzhiC div .nowPay {
  float: right;
  padding: 8px 16px;
  background: #298deb;
  color: #fff;
  border-radius: 8px;
  margin-right: 20px;
  -webkit-box-shadow: #7bbaf5 0 0 7px 1px;
  box-shadow: 0 0 7px 1px #7bbaf5;
  cursor: pointer;
  margin-top: 20px;
}

.chongzhiC div .aa {
  position: relative;
  width: 250px;
  height: 250px;
}

.chongzhiC div .is_payImgBg {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  line-height: 250px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  margin: 0 0;
  background: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}

.qrcode {
  display: inline-block;
  img {
    width: 132px;
    height: 132px;
    background-color: #fff; //设置白色背景色
    padding: 6px; // 利用padding的特性，挤出白边
    box-sizing: border-box;
  }
}
</style>
